<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Categories
      <router-link slot="right" :to="{ name: 'category.new' }" class="button">
        New Category
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
        <category-table :checkable="true"/>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import CategoryTable from '@/components/SSIPLENGG/CategoryTable.vue'
import HeroBar from '@/components/HeroBar'
export default {
  name: 'Categories',
  components: {
    HeroBar,
    TitleBar,
    CategoryTable
  },
  computed: {
    titleStack () {
      return ['Admin', 'Categories']
    }
  }
}
</script>
